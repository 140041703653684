<template>
  <div class="wrapper">
    <van-cell-group>
      <van-field v-model="oldPassword" type="password" label="原密码" placeholder="请输入原密码" />
    </van-cell-group>
    <div class="line"></div>
    <van-cell-group>
      <van-field v-model="newPassword" type="password" @blur="onBlur" label="新密码" placeholder="请输入新密码" />
      <van-field v-model="surePassword" type="password" label="确认密码" placeholder="请输入确认密码" />
    </van-cell-group>
    <div class="btn" @click="onSave">保 存</div>
  </div>
</template>
<script>
let self = null;
import { Toast } from 'vant';
import { parse } from 'qs';
import { setTimeout } from 'timers';
export default {
  components: {
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      surePassword: '',
      userInfo: JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created() {
    self = this;
  },
  methods: {
    onBlur() {
      if (this.newPassword.length < 6 || this.newPassword.length > 16) {
        Toast('密码长度必须为6到16位');
        return;
      }
    },
    onSave() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      console.log(userInfo);
      if (this.oldPassword == '') {
        Toast('请输入原密码');
        return;
      }
      if (this.newPassword == '') {
        Toast('请输入新密码');
        return;
      }
      if (this.newPassword.length < 6 || this.newPassword.length > 16) {
        Toast('密码长度必须为6到16位');
        return;
      }
      if (this.surePassword == '') {
        Toast('请再次确认密码');
        return;
      }
      if (this.newPassword != this.surePassword) {
        Toast('两次密码输入不一致，请重新输入');
        return;
      }
      let params = {
        userId: userInfo.userId,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      }
      let url = this.userInfo.user.storeId ? '/system/store/user/updatePwd' : '/system/enterprise/user/updatePwd';
      this.$http('put', url, params).then(res => {
        if (res.code == 200) {
          Toast('密码修改成功，请重新登录');
          this.logout();

        } else {
          Toast(res.msg);
        }
      })
    },
    logout() {
      this.$http('delete', '/auth/logout').then(res => {
        console.log('退出登录', res);
        localStorage.removeItem('userInfo');
        setTimeout(() => {
          this.$router.push('/login');
        }, 1000)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ds_flex {
  display: flex;
  display: -webkit-flex;
}
.ju_center {
  justify-content: center;
  -webkit-justify-content: center;
}
.al_center {
  align-items: center;
  -webkit-align-items: center;
}
.wrapper {
  background: #f8f8f8;
  min-height: 100vh;
}
.line {
  height: 0.15rem;
  background: #f8f8f8;
}
/deep/ .van-cell {
  padding: 0.35rem 0.3rem;
  .van-cell__title {
    font-size: 0.28rem;
    font-weight: bold;
    color: #333333;
  }
  .van-field__control {
    font-size: 0.28rem;
    color: #333333;
  }
  .van-field__control::-webkit-input-placeholder {
    color: #c5c5c5;
    font-size: 0.28rem;
  }
}
.btn {
  margin: 2rem 0.4rem;
  height: 0.76rem;
  line-height: 0.76rem;
  font-size: 0.28rem;
  font-weight: bold;
  color: #fff;
  background: #3780ff;
  border-radius: 8px;
  text-align: center;
}
</style>
